import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TIPOS_CHEQUERAS, SERVICES, ACCION_CODIGO, TIPO_OPERACION } from '../../properties';
import { ICONS } from '../../assets/icons';
import { showToken, okButton, recargaCartera, setCoordenadas, setModalInfo } from '../../redux/actions';
import {
    makeCall, nWithCommas, encryptValues, getSaldoLiquido, getCoordenadas,
    openTip, closeTip,
    shareDetail
} from '../../utils';
import Reload from '../reload';
import M from 'materialize-css';
import ip from 'ip';
import firebase from 'firebase';
import NumberFormat from 'react-number-format';
import moment from 'moment';

class Retiro extends Component {

    state = {
        importe: "",
        errorMsg: false,
        cuentaSelected: false,
        loading: false,
        softToken: false,
        operacionExitosa: false,
        maximoRetiro: false,
        myIp: ip.address(),
        detail: false,
        test: 12000,
        saldoOperacion: 0,
        concepto: ''
    }



    async componentDidMount() {
        M.updateTextFields();

        try {
            let saldoOperacion = await getSaldoLiquido({
                dataUser: this.props.dataUser,
                currentContrato: this.props.currentContrato,
                TipoSaldo: "R"
            });
            this.setState({ saldoOperacion });
        } catch (error) {

        }

        this.getFilterChequera(TIPOS_CHEQUERAS.RETIRO);
    }

    onChangeValue = (event) => {
        let { saldoOperacion } = this.state;
        //let { saldoOperacion } = this.props;
        let errorMsg = false;
        let { id, value } = event.target;
        if (id === "importe") {

            if (parseFloat(value) > saldoOperacion.totalSaldoMXP) {
                errorMsg = "No cuentas con saldo líquido suficiente para realizar la operación."
            }
            this.setState({ [id]: value, errorMsg });

        } else {
            this.setState({ [id]: value });
        }

    }

    onChangeValueCheck = (event) => {
        let { saldoOperacion, cuentaSelected } = this.state;
        // let { saldoOperacion } = this.props;
        let { id } = event.target;
        let target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let ImpMax = parseFloat(saldoOperacion.totalSaldoMXP).toFixed(2);
        if (cuentaSelected) {
            ImpMax = parseFloat(cuentaSelected.ImpMax).toFixed(2);
        }




        this.setState({ importe: value ? ImpMax : "" }, () => {
            firebase.analytics().logEvent('Opera_retiro_maximo');
            setTimeout(() => {
                M.updateTextFields();
            }, 100);

        });


        this.setState({ [id]: value }, () => {
            M.updateTextFields();
        });
    }


    submitData = async () => {
        let { importe, cuentaSelected, myIp, concepto } = this.state;
        this.setState({ loading: true });
        if (await getCoordenadas(this.props)) {
            this.setState({ loading: false });
            this.props.showToken({
                idUsuario: this.props.dataUser.IdUsuario, accion: ACCION_CODIGO.ACCESO,
                title: "Código de Seguridad",
                message: "Ingresar el código de seguridad generado en tu aplicación móvil (token) para validar tu operación.",
                txtButton: "Confirmar Operación",
                onlyPass: true,
                esOperacion: true,
                actionClick: async (softToken) => {
                    let P = await new Promise(async (resolve) => {
                        try {
                            openTip();
                            let bodyOperaEfectivo = SERVICES.OPERA_EFECTIVO.body;
                            bodyOperaEfectivo.IdUsuario = encryptValues(this.props.dataUser ? this.props.dataUser.IdUsuario : "");
                            bodyOperaEfectivo.Contrato = encryptValues(this.props.currentContrato ? this.props.currentContrato.Contrato : "");
                            bodyOperaEfectivo.Importe = parseFloat(importe);
                            bodyOperaEfectivo.TipoOper = TIPO_OPERACION.RETIRO;
                            bodyOperaEfectivo.Banco = cuentaSelected.Banco;
                            bodyOperaEfectivo.Titular = cuentaSelected.Titular;
                            bodyOperaEfectivo.Cuenta = encryptValues(cuentaSelected.Cuenta);
                            bodyOperaEfectivo.SoftToken = encryptValues(softToken);
                            bodyOperaEfectivo.Latitud = encryptValues(this.props.coordenadas.latitude);
                            bodyOperaEfectivo.Longitud = encryptValues(this.props.coordenadas.longitude);
                            bodyOperaEfectivo.Pais = encryptValues(this.props.coordenadas.pais);
                            bodyOperaEfectivo.Ciudad = encryptValues(this.props.coordenadas.ciudad);
                            bodyOperaEfectivo.Estado = encryptValues(this.props.coordenadas.estado);
                            bodyOperaEfectivo.Direccion = encryptValues(this.props.coordenadas.direccion);
                            bodyOperaEfectivo.IP = encryptValues(this.props.ip);
                            bodyOperaEfectivo.DeviceToken = this.props.dataUser ? this.props.dataUser.deviceToken : "";
                            bodyOperaEfectivo.Concepto = concepto;

                            let resultOperaEfectivo = await makeCall(SERVICES.OPERA_EFECTIVO, bodyOperaEfectivo);
                            this.setState({ loading: false });
                            if (resultOperaEfectivo && resultOperaEfectivo.response) {
                                closeTip();

                                if (resultOperaEfectivo.response.Exito === 409) {
                                    this.setState({ errorMsg: resultOperaEfectivo.response.MensajeError });
                                } else if (resultOperaEfectivo.response.Exito !== 200) {

                                    this.setState({ errorMsg: resultOperaEfectivo.response.MensajeError });


                                } else if (resultOperaEfectivo.response.Exito === 408) {

                                    this.setState({ errorMsg: resultOperaEfectivo.response.MensajeError });
                                } else {
                                    let detail = resultOperaEfectivo.response['TTOPERACIONES']['TT-OPERACIONES'][0]
                                    this.setState({ operacionExitosa: true, detail }, () => {
                                        this.props.recargaCartera(true);
                                    });
                                }
                                this.setState({ loading: false });
                                firebase.analytics().logEvent('Opera_Retiro');
                                resolve(true);
                            } else {
                                this.setState({ errorMsg: "Ocurrió un error inesperado intente nuevamente." });
                                closeTip();
                                resolve(true);
                            }

                        } catch (error) {
                            this.setState({ errorMsg: "Ocurrió un error inesperado intente nuevamente." });
                            closeTip();
                            resolve(true);

                        }
                    });


                    return P;

                }
            });
        }

    }

    render() {
        let { importe, cuentaSelected, loading, operacionExitosa,
            maximoRetiro, errorMsg,
            detail, saldoOperacion } = this.state;

        // let {saldoOperacion} = this.props;

        if (operacionExitosa) {
            return (
                <React.Fragment>
                    <div className="row marginLR-1r mt-1" id="detalleRecibo">
                        <div id="cabeceraComprobante">
                            <div className="col s2 center-align">
                                <img src={ICONS.LOGO_CIRCULO} alt="LOGO" />
                            </div>
                            <div className="col s6" style={{ paddingTop: "1.5rem" }}>
                                <span>Operación de retiro de su contrato de inversión en Valmex</span>
                            </div>
                            <div className='col s4 right-align' style={{ paddingTop: "1.5rem" }}>
                                <span>{moment().format('yyyy/MM/DD HH:mm')}</span>
                            </div>
                        </div>
                        <div className="col s12 mt-1 detailCard">
                            <div className="row containerDetalleOpera">
                                <div className="col s12 navyBackground white-text" style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
                                    <span>{cuentaSelected.DescBanco}</span>  <span className="floatRight">*****{`${cuentaSelected.Cuenta}`.substring(`${cuentaSelected.Cuenta}`.length - 4)}</span>
                                </div>
                                <div className="col s12">
                                    <p className="noMBottom ft-10">Tipo de Operación</p>
                                    <p className="noMTop">{detail.TipoOper}</p>
                                </div>
                                <div className="col s12 bgGray">
                                    <p className="noMBottom ft-10">Importe</p>
                                    <p className="noMTop">${nWithCommas(parseFloat(importe).toFixed(2).toString())}</p>
                                </div>

                                <div className="col s6 left-align">
                                    <p className="noMBottom ft-10">Fecha de operación</p>
                                    <p className="noMTop">{detail.FOper}</p>
                                </div>
                                <div className="col s6 left-align">
                                    <p className="noMBottom ft-10">Hora de operación</p>
                                    <p className="noMTop">{detail.Hora}</p>
                                </div>
                                <div className="col s12 bgGray">
                                    <p className="noMBottom ft-10">Fecha de liquidación</p>
                                    <p className="noMTop">{detail.FLiq}</p>
                                </div>
                                <div className="col s6 left-align">
                                    <p className="noMBottom ft-10">No. de contrato</p>
                                    <p className="noMTop">{this.props.currentContrato ? this.props.currentContrato.Contrato : ""}</p>
                                </div>
                                <div className="col s6 left-align">
                                    <p className="noMBottom ft-10">Folio</p>
                                    <p className="noMTop">{detail.Folio}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col s12 ft-12" id="footerCardDetail">
                            Apreciable cliente recuerde que la única información oficial al respecto de su saldo, es la que usted recibe en su estado de cuenta.
                        </div>
                    </div>
                    <div className="col s12 center-align">
                        <button onClick={() => {
                            shareDetail('detalleRecibo');
                        }} className="btn primaryBtn_oc width100 boldText mb-1" >Compartir</button>
                    </div>
                </React.Fragment>
            )
        }
        return (
            <div className="row marginLR-1r">
                <div className="col s12 mt-1">

                    {
                        saldoOperacion === false
                            ?
                            <div className="row infoSaldo center-align">
                                <Reload action={() => { }} />
                            </div>
                            :
                            <div className="row infoSaldo">
                                <div className="col s12 left-align">
                                    Saldo líquido disponible
                                </div>
                                <div className="col s12 left-align boldText">
                                    ${nWithCommas(parseFloat(saldoOperacion.totalSaldoMXP ?? 0).toFixed(2))}
                                </div>
                            </div>
                    }


                </div>
                <div className="col s12 mt-1">
                    <span><img src={ICONS.UNO} alt="icon uno" /></span>  Indica el importe a retirar:
                </div>
                <div className="input-field col s12 mt-1">

                    <NumberFormat
                        id="importe"
                        thousandSeparator={','}
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale
                        value={importe}
                        isNumericString
                        allowNegative={false}
                        prefix="$"
                        onClick={() => {
                            firebase.analytics().logEvent('Opera_Importe_retiro');
                        }}
                        onValueChange={(values) => {
                            let { saldoOperacion, cuentaSelected } = this.state;
                            // let { saldoOperacion } = this.props;
                            let errorMsg = false;

                            if (!cuentaSelected) {
                                errorMsg = "Seleccione una cuenta bancaria para poder continuar.";
                            } else {
                                if (parseFloat(values.value) > saldoOperacion.totalSaldoMXP) {
                                    errorMsg = "No cuentas con saldo líquido suficiente para realizar la operación."
                                } else if (parseFloat(values.value) > parseFloat(cuentaSelected.ImpMax)) {
                                    errorMsg = `El monto de retiro es mayor al máximo permitido $${nWithCommas(parseFloat(cuentaSelected.ImpMax).toFixed(2))}, verifique la configuración de sus chequeras. `
                                }
                            }



                            this.setState({ importe: values.value, errorMsg })

                        }}
                    />

                    <label htmlFor="importe">Importe</label>
                    {errorMsg ?
                        <span className="helper-text red-text text-darken-3" >{errorMsg}</span>
                        : null}
                </div>

                <div className="col s12 switch mt-2 maxRetiro">
                    Retirar máximo disponible
                    <label className="floatRight">
                        <input type="checkbox"
                            disabled={saldoOperacion === false}
                            id="maximoRetiro"
                            onChange={this.onChangeValueCheck}
                            checked={maximoRetiro}
                            value={maximoRetiro} />
                        <span className="lever"></span>
                    </label>
                </div>
                <div className="col s12 mt-1">
                    <span><img src={ICONS.DOS} alt="icon dos" /></span>  Selecciona una cuenta para enviarle el efectivo:
                </div>
                <div className="col s12 mt-1">
                    <div className="row boxDepositoRetiro">
                        {
                            this.getTipoChequera(TIPOS_CHEQUERAS.RETIRO)
                        }
                    </div>
                </div>
                {/* <div className="input-field col s12 mt-1">

                    <input id="concepto" type="text" onChange={(e) => {
                        this.setState({ concepto: e.target.value })
                    }} value={this.state.concepto} maxLength={30}/>
                    <label htmlFor="concepto">Concepto</label>
                    <span className='right ft-12'>{this.state.concepto.length}/30</span>
                </div> */}
                {

                    (cuentaSelected && importe.trim() !== "" && parseFloat(importe) >= 0.01) ?
                        <div className="col s12 mt-1 center-align">
                            {
                                loading ?
                                    <Reload action={() => { }} />

                                    :

                                    <button className="btn primaryBtn_oc width100 boldText"
                                        onClick={this.submitData} disabled={errorMsg}
                                    >Retirar</button>
                            }

                        </div>

                        : null

                }
            </div>
        )
    }

    getFilterChequera = (tipo) =>{
       
        let filterChequera = this.props.chequera.filter((v, i) => {
            if (v.TipoCta === tipo && v.Contrato === this.props.currentContrato.Contrato
                && v.Opera === "1"

            ) {
                return v;
            }
        });

        if(!filterChequera.length){
            this.props.setModalInfo({
                title: "Atención",
                text: `No cuenta con chequeras registradas para realizar retiros de efectivo, por favor contacte a su promotor.`,
                txtButton: "Entendido"
            })
        }
    }

    getTipoChequera = (tipo) => {
        let { cuentaSelected } = this.state;

      

        if (this.props.chequera && this.props.chequera.length) {
            let filterChequera = this.props.chequera.filter((v, i) => {
                if (v.TipoCta === tipo && v.Contrato === this.props.currentContrato.Contrato) {
                    return v;
                }
            });

            if (filterChequera.length) {


                return (<div className="col s12">
                    <ul className="collection">
                        {
                            filterChequera.map((v, i) => {



                                return (
                                    <li className={`${cuentaSelected.CLABE === v.CLABE && cuentaSelected.Cuenta === v.Cuenta ?
                                        'cuentaSelected '
                                        : ""} collection-item row hoverContent`}
                                        disabled={v.Opera === "0"}
                                        onClick={() => {
                                            let errorMsg = false;

                                            if (this.state.importe.trim() !== "" && (parseFloat(this.state.importe) > parseFloat(v.ImpMax))) {
                                                errorMsg = `El monto de retiro es mayor al máximo permitido $${nWithCommas(parseFloat(v.ImpMax).toFixed(2))}, verifique la configuración de sus chequeras. `
                                            }

                                            if (v.Opera === "1") {

                                                if (v.HoraFin && v.HoraIni) {

                                                    let fechaActual = new Date();
                                                    let ha = fechaActual.getHours();  //hora actual
                                                    let ma = fechaActual.getMinutes(); //minuto actual

                                                    if (Date.parse(`01/01/2011 ${ha}:${ma}:00`) < Date.parse(`01/01/2011 ${v.HoraIni}:00`)) {

                                                        // this.props.okButton({
                                                        //     message: `Apreciable cliente, no ha alcanzado el horario inicial para realizar esta operación (${v.HoraIni} Hrs.), por favor intente más tarde.`,
                                                        //     title: "Advertencia", txtButton: "Ok",
                                                        //     statusCode: 404
                                                        // });

                                                        this.setState({ errorMsg: `Apreciable cliente, no ha alcanzado el horario inicial para realizar esta operación (${v.HoraIni} Hrs.), por favor intente más tarde.` });

                                                    } else if (Date.parse(`01/01/2011 ${ha}:${ma}:00`) > Date.parse(`01/01/2011 ${v.HoraFin}:00`)) {
                                                        // this.props.okButton({
                                                        //     message: `Apreciable cliente, ha alcanzado el horario límite para realizar esta operación (${v.HoraFin} Hrs.) Horario de la Ciudad de México (GMT-6), favor de intente el día de mañana.`,
                                                        //     title: "Advertencia", txtButton: "Ok",
                                                        //     statusCode: 404
                                                        // });

                                                        this.setState({ errorMsg: `Apreciable cliente, ha alcanzado el horario límite para realizar esta operación (${v.HoraFin} Hrs.) Horario de la Ciudad de México (GMT-6), favor de intente el día de mañana.` });
                                                    }
                                                    else {
                                                        let ImpMax = v.ImpMax > 0 ? parseFloat(v.ImpMax).toFixed(2) : 0;
                                                        this.setState({ cuentaSelected: v, operacionExitosa: false, ImpMax, errorMsg });
                                                    }

                                                } else {
                                                    let ImpMax = v.ImpMax > 0 ? parseFloat(v.ImpMax).toFixed(2) : 0;
                                                    this.setState({ cuentaSelected: v, operacionExitosa: false, ImpMax, errorMsg });
                                                }
                                            }
                                        }
                                        }>
                                        <div className="col s10">
                                            <p style={{ marginTop: '0px', marginBottom: '1px' }}>
                                                <span className="title">{v.DescBanco} - *****{`${v.Cuenta}`.substring(`${v.Cuenta}`.length - 4)}</span>
                                            </p>
                                            <p style={{
                                                textTransform: 'capitalize',
                                                fontSize: '12px',
                                                marginTop: '0px'
                                            }}>{v.Nombre.toLowerCase()} - {v.Tipo}</p>
                                        </div>
                                        <div className="col s2 center-align">
                                            {
                                                cuentaSelected.CLABE === v.CLABE && cuentaSelected.Cuenta === v.Cuenta ?
                                                    <img src={ICONS.CHECK_BLACK} className="mt-1" alt='Check' />
                                                    : null
                                            }

                                        </div>

                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                )

            }



        }

        return <div></div>;

    }

}


const mapStateToProps = state => {
    let { dataUser, currentContrato, menuPerfil, contratosUser,
        chequera, history, coordenadas, saldoTotal, saldoOperacion,
        ip
    } = false;
    if (state.servicesActions) {
        dataUser = state.servicesActions.dataUser || false;
        currentContrato = state.servicesActions.currentContrato || false
        contratosUser = state.servicesActions.contratosUser || false
        chequera = state.servicesActions.chequera || false

    }

    if (state.commonsActions) {
        ip = state.commonsActions.ip || false;
        menuPerfil = state.commonsActions.menuPerfil || false;
        history = state.commonsActions.history || false;
        coordenadas = state.commonsActions.coordenadas || false;
        saldoTotal = state.commonsActions.saldoTotal || 0;
        saldoOperacion = state.commonsActions.saldoOperacion || 0;
    }

    return {
        dataUser, currentContrato, menuPerfil, contratosUser, chequera, history,
        coordenadas, saldoTotal, saldoOperacion, ip
    };
};

export default connect(mapStateToProps,
    {
        showToken, okButton, recargaCartera, getCoordenadas, setCoordenadas, setModalInfo
    })(Retiro);