import React from 'react';
import ReactDOM from 'react-dom';
import 'materialize-css/dist/css/materialize.min.css'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as props from './properties';
import firebase from 'firebase';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Logo from './assets/logo.png';


firebase.initializeApp(props.FIREBASE_PROPS);

const db = firebase.firestore();

// Solución para problemas de conexión
db.settings({
  experimentalForceLongPolling: true,
  merge: true,
});


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </BrowserRouter>
    </Provider>
    {/* <div className='container'>
        <div className='row'>
          <div className='row'>
            <div className='col s12 align-center'>
              <img className='responsive-img' src={Logo} alt="logo" />
            </div>
          </div>
          <div className='col s12 align-center'>
          <div class="valign-wrapper" style={{minHeight: "500px"}}>
            <div className='row'>
              <div className='col s12' >
              <p><h5>Estimados Clientes, <br/></h5></p> 
              
            <p style={{textAlign: "justify"}}> <h5>Nos dirigimos a ustedes para informarles que nuestro sitio web se encuentra actualmente en mantenimiento. Esta acción es parte de nuestro compromiso constante de trabajar para ustedes y mejorar la calidad de nuestros servicios.</h5></p>
            <p style={{textAlign: "justify"}}><h5>Agradecemos su comprensión y paciencia durante este período. Mientras tanto, pueden continuar realizando sus consultas y operaciones a través de los siguientes canales alternativos:</h5></p>

            <p>
              <ul style={{marginLeft: "4rem"}}>
                <li><h5><b>Portal de consulta de Valmex Casa de Bolsa:</b> <a href='https://servicios.valmex.com.mx/Cuenta/Ingreso' target='_blank'>https://servicios.valmex.com.mx/Cuenta/Ingreso</a></h5></li>
                <li><h5><b>Página anterior de Valmex SI:</b> <a href='https://valmexsi.valmex.com.mx/'>https://valmexsi.valmex.com.mx/</a></h5></li>
                <li><h5><b>Asesor personal: </b> también pueden contactar directamente a su asesor para cualquier consulta o requerimiento.</h5></li>
              </ul>
            </p>

            <p>
              <h5>Nuestro equipo está trabajando arduamente para restablecer el servicio lo antes posible y seguir ofreciéndoles la mejor experiencia.</h5>
            </p>
            <p>
              <h5>Gracias por su comprensión y confianza.</h5>
            </p>
            <p><h5>Atentamente,</h5></p>
            <p><h5><b>Valmex Casa de Bolsa</b></h5></p>
              </div>
            </div>
          
          </div>
          </div>
        </div>
    </div> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
