import React, { Component } from 'react';
import { connect } from 'react-redux';
import {showModalFchNac} from '../redux/actions';



class ViewPdf extends Component {

    state = {
        url: false,
        pdfDoc: null,
        pageNum: 1,
        pageRendering: false,
        pageNumPending: null,
        scale: 1.5,
        numPages: null
        // canvas: document.getElementById('the-canvas'),
        // ctx: document.getElementById('the-canvas').getContext('2d')
    }

    componentDidMount() {
        const blob = this.base64toBlob(this.props.data.link);
        const url = URL.createObjectURL(blob);
        this.setState({ url });

        this.makeViwer(this.props.data.link);
    }



    makeViwer(data) {
        
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
        var pdfData = atob(base64WithoutPrefix);

        // Loaded via <script> tag, create shortcut to access PDF.js exports.
        var pdfjsLib = window['pdfjs-dist/build/pdf'];

        // The workerSrc property shall be specified.
        pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.min.js';

        // Using DocumentInitParameters object to load binary data.
        var loadingTask = pdfjsLib.getDocument({ data: pdfData });

        loadingTask.onPassword =  (updatePassword, reason) =>{ 
            if (reason === 1) { // need a password

                this.props.showModalFchNac({
                    cb: (new_password)=>{
                        updatePassword(new_password);
                    }
                });
                
              } 
         };

        loadingTask.promise.then((pdf) => {

            this.setState({ pdfDoc: pdf, numPages: pdf.numPages }, () => {
                this.renderPage(1);
            });

        }, (reason) => {
            // PDF loading error
            console.error(reason);
        });
    }


    renderPage(num) {
        let { pageRendering, pdfDoc, pageNumPending, scale } = this.state;
        pageRendering = true;
        // Using promise to fetch the page
        pdfDoc.getPage(num).then((page) => {
            let canvas = document.getElementById('the-canvas');
            let ctx = document.getElementById('the-canvas').getContext('2d');
            var viewport = page.getViewport({ scale: scale });
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            var renderContext = {
                canvasContext: ctx,
                viewport: viewport
            };
            var renderTask = page.render(renderContext);

            // Wait for rendering to finish
            renderTask.promise.then(() => {
                pageRendering = false;
                if (pageNumPending !== null) {
                    // New page rendering is pending
                    this.renderPage(pageNumPending);
                    this.setState({ pageNumPending: null });
                }
            });
        });
    }

    queueRenderPage(num) {
        let { pageRendering, pageNumPending } = this.state;
        if (pageRendering) {
            pageNumPending = num;

            this.setState({ pageNumPending });
        } else {
            this.renderPage(num);
        }
    }

    onNextPage() {
        let { pageNum, pdfDoc } = this.state;
        if (pageNum >= pdfDoc.numPages) {
            return;
        }
        pageNum++;

        this.setState({ pageNum });
        this.queueRenderPage(pageNum);
    }

    onPrevPage() {
        let { pageNum } = this.state;
        if (pageNum <= 1) {
            return;
        }
        pageNum--;
        this.setState({ pageNum });
        this.queueRenderPage(pageNum);
    }

    downloadPdf() {
        const blob = this.base64toBlob(this.props.data.link);
        const url = URL.createObjectURL(blob);
         const a = document.createElement('a');
         a.setAttribute('target', '_blank');
         a.href = url;
         a.download = this.props.data.nameDoc + '.pdf';
         a.click();

        //window.open(url, '_blank');
    }


    base64toBlob = (data) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    };

    render() {

        if (this.state.url) {
            return (
                <div className="container">
                    <div className="row noMBottom">
                        <div className="col s12">
                            <div className="row mt-1">
                                <div className="col s4 left-align">
                                         <span
                                    onClick={() => this.onPrevPage()}
                                    ><i className="material-icons cursorPointer"
                                    
                                    > arrow_backward</i> </span>
                                </div>
                                <div className="col s4 center-align">
                                    <span>Pág.<span id="page_num">{this.state.pageNum}</span> / <span id="page_count">
                                        {this.state.numPages}
                                    </span> <i className="material-icons right cursorPointer"
                                    onClick={() => this.downloadPdf()}
                                    >cloud_download</i></span>
                                </div>
                               
                                <div className="col s4 right-align">
                                    <span
                                    onClick={() => this.onNextPage()}
                                    ><i className="material-icons cursorPointer"
                                    
                                    > arrow_forward</i> </span>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col s12">
                        <canvas id="the-canvas" className="pdf-viewer"></canvas>
                    </div>
                </div>

                // <object className="pdf-viewer"
                //     data={this.state.url}
                //     type="application/pdf"

                //     style={{width: '100%', height: '95vh !important'}}
                // ></object>

            )
        }
        return (
            <div className="container">
                <div className="row">
                    <div className='col s12 center-align'>
                        Cargando...

                    </div>
                </div>
            </div>
        )


    }

}

export default connect(null, { showModalFchNac })(ViewPdf);
